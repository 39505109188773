/**-----------------------------------------------------------------------------------
    CSS INDEX
    ===================

    1. Default
    2. Header
    3. Navigation Menu
        - Desktop Navigation
        - Mobile Navigation
    4. Main Content

 */
/*--------------------------------------------------------------
    1. Default
--------------------------------------------------------------*/
@font-face {
    font-family: 'Avenir';
    src: url('../public/fonts/Avenir-Book.woff2') format('woff2'),
        url('../public/fonts/Avenir-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('../public/fonts/Avenir-Heavy.woff2') format('woff2'),
        url('../public/fonts/Avenir-Heavy.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('../public/fonts/Avenir-Roman.woff2') format('woff2'),
        url('../public/fonts/Avenir-Roman.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('../public/fonts/Avenir-Black.woff2') format('woff2'),
        url('../public/fonts/Avenir-Black.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PT Sans';
    src: url('../public/fonts/PTSans-Regular.woff2') format('woff2'),
        url('../public/fonts/PTSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PT Sans';
    src: url('../public/fonts/PTSans-Bold.woff2') format('woff2'),
        url('../public/fonts/PTSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

* {
    outline-color: transparent;
    outline-style: none;
}

:root {
    --white: #FFFFFF;
    --light: #F2F6FF;
    --dark: #24272C; 
    --gray: #6C737D;
    --primary: #26EDE1;
    --success: #34A853;
    --danger: #EA4335;
    --warning: #FFBF1C;
    --info: #4285F4;
    --border: #EDEDED;
    --font-white: #FFFFFF;
    --box-shadow: 0 0 6px -4px #00000014, 0 0 16px 0 #0000000d, 0 0 28px 8px #0000000d;
}
body {
    font-family: 'Avenir';
    color: var(--dark);
    font-size: 16px;
    overflow-x: hidden;
    scroll-behavior: smooth;
}
::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
}
::-webkit-scrollbar-track {
    background-color: transparent !important;
}
::-webkit-scrollbar-thumb {
    background: var(--border);
    border-radius: .325rem;
}
::selection {
    color: var(--font-white);
    background-color: var(--primary);
}
::-moz-selection {
    color: var(--font-white);
    background-color: var(--primary);
}
a {
    color: mediumblue;
    text-decoration: none;
}
a:hover, a:focus{
    text-decoration: none;
}
a:hover {
    color: mediumblue;
}
a:-webkit-any-link:focus-visible {
    outline-offset: 0;
}
a:-webkit-any-link:focus-visible {
    outline-offset: unset;
}
/*  ==================================================
    1. Comman
================================================== */
.rs-title{
    position: relative;
    font-size: 24px;
    font-weight: 700;
    color: var(--dark);
    padding-bottom: 12px;
    margin-bottom: .75rem;
}
.rs-title::before{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 116px;
    height: 3px;
    background-color: #26EDE1;
    border-radius: 50px;
}
.rs-title.center{
    text-align: center;
}
.rs-title.center::before{
    left: 50%;
    transform: translateX(-50%);
}
.pt-50{
    padding-top: 50px;
}
.pb-50{
    padding-bottom: 50px;
}
@media (max-width: 1199.98px) {
    .pt-50{
        padding-top: 45px;
    }
    .pb-50{
        padding-bottom: 45px;
    }  
}
@media (max-width: 991.98px) {
    .rs-title{
        font-size: 23px;
        margin-bottom: .25rem;
    }
    .pt-50{
        padding-top: 40px;
    }
    .pb-50{
        padding-bottom: 40px;
    }
}
@media (max-width: 767.98px) {
    .rs-title{
        font-size: 22px;
    }
    .rs-title::before{
        width: 106px;
        height: 3px;
    }
    .pt-50{
        padding-top: 35px;
    }
    .pb-50{
        padding-bottom: 35px;
    }
}
@media (max-width: 575.98px) {
    .rs-title{
        font-size: 21px;
        margin-bottom: .5rem;
    }
    .rs-title::before{
        width: 96px;
        height: 3px;
    }
    .pt-50{
        padding-top: 30px;
    }
    .pb-50{
        padding-bottom: 30px;
    }
}
@media (max-width: 399.98px) {
    .rs-title{
        font-size: 20px;
        margin-bottom: .25rem;
    }
    .rs-title::before{
        width: 86px;
        height: 3px;
    }
    .pt-50{
        padding-top: 25px;
    }
    .pb-50{
        padding-bottom: 25px;
    }
}
@media (max-width: 349.98px) {
    .rs-title{
        font-size: 19px;
    }
    .rs-title::before{
        width: 76px;
        height: 3px;
    }
    .pt-50{
        padding-top: 20px;
    }
    .pb-50{
        padding-bottom: 20px;
    }
}
@media (max-width: 319.98px) {
}
/*  ==================================================
    1. Header
================================================== */
.header{
    /* position: -webkit-sticky;
    position: sticky;
    top: 0; */
    text-align: center;
    padding: 50px 0;
    background-color: var(--dark);
}
.header .logo img {
    height: 30px;
}
@media (max-width: 1199.98px) {
    .header{
        padding: 40px 0;
    }
}
@media (max-width: 991.98px) {
    .header{
        padding: 35px 0;
    }
}
@media (max-width: 767.98px) {  
    .header{
        padding: 30px 0;
    }
    .header .logo img {
        height: 28px;
    }
}
@media (max-width: 575.98px) {
    .header{
        padding: 25px 0;
    }
    .header .logo img {
        height: 26px;
    }
}
@media (max-width: 399.98px) {
    .header{
        padding: 20px 0;
    }
    .header .logo img {
        height: 24px;
    }
}
@media (max-width: 349.98px) {
}
@media (max-width: 319.98px) {
}

/*  ==================================================
    2. Hero
================================================== */
.rs-hero{
    position: relative;
    background: linear-gradient(180deg, #24272c 36%, #ffffff 0);
    z-index: 1;
}
.rs-hero .rs-hero-box{
    background-color: var(--white);
    border-radius: 1.75rem;
    padding: 3rem;
    box-shadow: 0 30px 50px 0 rgb(0 0 0 / 5%);
}
.rs-hero .rs-hero-box .rs-hero-media{
    text-align: center;
}
.rs-hero .rs-hero-box .rs-hero-scanner{
    text-align: center;
}
.rs-hero .rs-hero-box .rs-hero-scanner .rs-hero-content h1{
    font-size: 30px;
    line-height: normal;
    font-weight: 700;
    color: var(--dark);
    margin-bottom: .25rem;
}
.rs-hero .rs-hero-box .rs-hero-scanner .rs-hero-content p{
    font-size: 1rem;
    color: var(--gray);
    margin-bottom: 2rem;
}
.rs-hero .rs-hero-box .rs-how-work{
    text-align: center;
}
.rs-hero .rs-hero-box .rs-how-work h6{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    line-height: normal;
    font-weight: 600;
    width: 56px;
    height: 34px;
    border-radius: 50px;
    background-color: var(--light);
    color: var(--white);
    margin:0 auto 1rem;
}
.rs-hero .rs-hero-box .rs-how-work.step-1 h6{
    background: rgb(241,72,116);
    background: linear-gradient(90deg, rgba(249,186,57,1) 0%, rgba(241,72,116,1) 100%);
}
.rs-hero .rs-hero-box .rs-how-work.step-2 h6{
    background: rgb(229,106,186);
    background: linear-gradient(90deg, rgba(229,106,186,1) 0%, rgba(119,76,240,1) 100%);
}
.rs-hero .rs-hero-box .rs-how-work.step-3 h6{
    background: rgb(102,219,251);
    background: linear-gradient(90deg, rgba(102,219,251,1) 0%, rgba(23,157,255,1) 100%);
}
.rs-hero .rs-hero-box .rs-how-work p{
    color: var(--dark);
    font-weight: 500;
    margin: 0;
}
.rs-hero .hero-shape{
    position: absolute;
    z-index: -1;
}
.rs-hero .hero-shape.hero-shape-down{
    left: 116px;
    top: 55%;
    transform: translateY(-45%);
}
.rs-hero .hero-shape.hero-shape-up{
    bottom: 60px;
    right: 116px;
}
@media (max-width: 1399.98px) {  
    .rs-hero .hero-shape{
        display: none;
    }
}
@media (max-width: 1199.98px) {  
}
@media (max-width: 991.98px) {
    .rs-hero .rs-hero-box{
        padding: 2.5rem;
    }
    .rs-hero .rs-hero-box .rs-hero-scanner .rs-hero-content h1{
        font-size: 28px;
    }
    .rs-hero .rs-hero-box .rs-hero-scanner .rs-hero-qrcode{
        margin-bottom: 1rem;
    }
}
@media (max-width: 767.98px) {  
    .rs-hero .rs-hero-box{
        border-radius: 1.5rem;
        padding: 2rem;
    }
    .rs-hero .rs-hero-box .rs-hero-scanner .rs-hero-content h1{
        font-size: 26px;
    }
    .rs-hero .rs-hero-box .rs-hero-scanner .rs-hero-content p{
        margin-bottom: 1.75rem;
    }
    .rs-hero .rs-hero-box .rs-hero-scanner .rs-hero-qrcode{
        margin-bottom: .75rem;
    }
    .rs-hero .rs-hero-box .rs-hero-media{
        max-width: 90%;
        margin: 0 auto;
    }
}
@media (max-width: 575.98px) {
    .rs-hero .rs-hero-box{
        border-radius: 1.25rem;
        padding: 1.5rem;
    }
    .rs-hero .rs-hero-box .rs-hero-scanner .rs-hero-content h1{
        font-size: 24px;
    }
    .rs-hero .rs-hero-box .rs-hero-scanner .rs-hero-content p{
        margin-bottom: 1.5rem;
    }
    .rs-hero .rs-hero-box .rs-hero-media{
        max-width: 95%;
        margin: 0 auto;
    }
    .rs-hero .rs-hero-box .rs-how-work h6{
        font-size: 17px;
    }
}
@media (max-width: 399.98px) {
    .rs-hero .rs-hero-box{
        border-radius: 1rem;
        padding: 1rem;
    }
    .rs-hero .rs-hero-box .rs-hero-scanner .rs-hero-content h1{
        font-size: 22px;
    }
    .rs-hero .rs-hero-box .rs-hero-scanner .rs-hero-content p{
        font-size: 15px;
        margin-bottom: 1.25rem;
    }
    .rs-hero .rs-hero-box .rs-how-work h6{
        font-size: 16px;
        width: 50px;
        height: 30px;
        margin: 0 auto .75rem;
    }
    .rs-hero .rs-hero-box .rs-how-work p{
        font-size: 15px;
    }
}
@media (max-width: 349.98px) {
    .rs-hero .rs-hero-box{
        border-radius: .75rem;
        padding: 1rem .75rem;
    }
    .rs-hero .rs-hero-box .rs-hero-scanner .rs-hero-content h1{
        font-size: 20px;
    }
    .rs-hero .rs-hero-box .rs-hero-scanner .rs-hero-content p{
        font-size: 14px;
        margin-bottom: 1rem;
    }
    .rs-hero .rs-hero-box .rs-how-work h6{
        font-size: 15px;
        height: 28px;
    }
    .rs-hero .rs-hero-box .rs-how-work p{
        font-size: 14px;
    }
}
@media (max-width: 319.98px) {
}
/*  ==================================================
    2. About
================================================== */
.rs-about p{
    font-weight: 500;
}
@media (max-width: 399.98px) {
    .rs-about p{
        font-size: 15px;
    }
}
@media (max-width: 349.98px) {
    .rs-about p{
        font-size: 14px;
    }
}
/*  ==================================================
    2. Benefits
================================================== */
.rs-benefits .rs-benefits-card{
    padding: 1.5rem;
    border-radius: 1rem;
    border: 1px solid var(--border);
}
.rs-benefits .rs-benefits-card .rs-benefits-media{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 1.5rem;
}
.rs-benefits .rs-benefits-card .rs-benefits-media span{
    font-size: 3rem;
    line-height: 2.75rem;
    font-weight: 600;
    color: #DFE4EB;
}
.rs-benefits .rs-benefits-card h3{
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    color: var(--dark);
}
.rs-benefits .rs-benefits-card p{
   color: var(--gray);
   margin: 0; 
}
@media (max-width: 1199.98px) {  
}
@media (max-width: 991.98px) {
}
@media (max-width: 767.98px) {  
}
@media (max-width: 575.98px) {
    .rs-benefits .rs-benefits-card{
        padding: 1.25rem;
    }
    .rs-benefits .rs-benefits-card .rs-benefits-media{
        margin-bottom: 1.25rem;
    }
}
@media (max-width: 399.98px) {
    .rs-benefits .rs-benefits-card{
        padding: 1rem;
    }
    .rs-benefits .rs-benefits-card .rs-benefits-media{
        margin-bottom: 1rem;
    }
    .rs-benefits .rs-benefits-card .rs-benefits-media img{
        width: 34px;
        height: 34px;
    }
    .rs-benefits .rs-benefits-card .rs-benefits-media span{
        font-size: 2.5rem;
        line-height: 2.25rem;
    }
    .rs-benefits .rs-benefits-card h3{
        font-size: 17px;
    }
    .rs-benefits .rs-benefits-card p{
       font-size: 15px;
    }
}
@media (max-width: 349.98px) {
    .rs-benefits .rs-benefits-card{
        padding: .75rem;
        border-radius: .75rem;
    }
    .rs-benefits .rs-benefits-card .rs-benefits-media{
        margin-bottom: .75rem;
    }
    .rs-benefits .rs-benefits-card h3{
        font-size: 16px;
    }
    .rs-benefits .rs-benefits-card p{
       font-size: 14px;
    }
}
@media (max-width: 319.98px) {
}
/*  ==================================================
    2. Faqs
================================================== */
.rs-faqs .accordion .accordion-item{
    background-color: #fff;
    border-radius: 1rem;
    margin-bottom: 1.5rem;
    border: 1px solid #DFE4EB;
}
.rs-faqs .accordion .accordion-item:last-child{
    margin-bottom: 0;
}
.rs-faqs .accordion .accordion-item .accordion-header .accordion-button{
    padding: 1.5rem;
    font-size: 17px;
    font-weight: 600;
    border-radius: 1rem !important;
}
.rs-faqs .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed){
    color: #24272C;
    background-color: transparent;
    box-shadow: unset;
}
.rs-faqs .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed)::after{
    filter: brightness(.1);
}
.rs-faqs .accordion .accordion-item .accordion-header .accordion-button.collapsed::after{
    opacity: .3;
}
.rs-faqs .accordion .accordion-item .accordion-header .accordion-button:focus{
    box-shadow:unset;
}
.rs-faqs .accordion .accordion-item .accordion-collapse .accordion-body{
    padding: 1.5rem;
    padding-top: 0 !important;
}
.rs-faqs .accordion .accordion-item .accordion-collapse .accordion-body p{
    font-size: 16px;
    font-weight: 500;
    color: #6C737D;
    margin: 0;
}
@media (max-width: 1199.98px) {  
}
@media (max-width: 991.98px) {
}
@media (max-width: 767.98px) {  
}
@media (max-width: 575.98px) {
    .rs-faqs .accordion .accordion-item{
        margin-bottom: 1.25rem;
    }
    .rs-faqs .accordion .accordion-item .accordion-header .accordion-button{
        padding: 1.25rem;
    }
    .rs-faqs .accordion .accordion-item .accordion-collapse .accordion-body{
        padding: 1.25rem;
    }
}
@media (max-width: 399.98px) {
    .rs-faqs .accordion .accordion-item{
        margin-bottom: 1rem;
    }
    .rs-faqs .accordion .accordion-item .accordion-header .accordion-button{
        font-size: 16px;
        padding: 1rem;
    }
    .rs-faqs .accordion .accordion-item .accordion-header .accordion-button::after{
        width: 1rem;
        height: 1rem;
        background-size:1rem;
    }
    .rs-faqs .accordion .accordion-item .accordion-collapse .accordion-body{
        padding: 1rem;
    }
    .rs-faqs .accordion .accordion-item .accordion-collapse .accordion-body p{
        font-size: 15px;
    }
}
@media (max-width: 349.98px) {
    .rs-faqs .accordion .accordion-item .accordion-header .accordion-button{
        font-size: 15px;
        padding: .75rem;
    }
    .rs-faqs .accordion .accordion-item .accordion-collapse .accordion-body{
        padding: .75rem;
    }
    .rs-faqs .accordion .accordion-item .accordion-collapse .accordion-body p{
        font-size: 14px;
    }
}
@media (max-width: 319.98px) {
}
/*  ==================================================
    2. Faqs
================================================== */
.rs-footer{
    background-color: #212331;
}
.rs-footer .rs-footer-content p{
    color: #fff;
    margin-top: 1.5rem;
    margin-bottom: 0;
}
.rs-footer .rs-footer-content ul{
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin-top: 1rem;
    margin-bottom: 0;
}
.rs-footer .rs-footer-content ul li{
    margin-right: .75rem;
    padding-right: .75rem;
    border-right: 1px solid #ddd;
}
.rs-footer .rs-footer-content ul li:last-child{
    padding-right: 0;
    margin-right: 0;
    border-right: none;
}
.rs-footer .rs-footer-content ul li a{
    color: #fff;
    transition: 0.3s;
}
.rs-footer .rs-footer-content ul li a:hover{
    color: #26EDE1;
}
.rs-footer .rs-app-btn{
    display: flex;
    align-items: center;
    justify-content: end;
}
.rs-footer .rs-app-btn a{
    margin-left: 1rem;
}
.rs-footer .rs-copyright{
    border-top: 1px solid #878997;
    color: #FFFFFF;
    text-align: center;
    font-weight: 500;
    margin: 1.5rem 0;
    padding-top: 1.5rem;
}
.rs-footer .rs-copyright span{
    color: #26EDE1;
}
@media (max-width: 1199.98px) {  
}
@media (max-width: 991.98px) {
    .rs-footer .rs-footer-content p br{
        display: none;
    }
    .rs-footer .rs-copyright{
        margin-top: 1rem;
    }
}
@media (max-width: 767.98px) { 
    .rs-footer{
        text-align: center;
    }
    .rs-footer .rs-footer-content ul{
        justify-content: center;
    }
    .rs-footer .rs-app-btn{
        justify-content: center;
    }
    .rs-footer .rs-app-btn a{
        margin-left: 0;
    }
    .rs-footer .rs-app-btn a:first-child{
        margin-right: 1rem;
    }
    .rs-footer .rs-copyright{
        margin-top: .75rem;
    }
}
@media (max-width: 575.98px) {
    .rs-footer .rs-footer-content p{
        margin-top: 1.25rem;
    }
    .rs-footer .rs-copyright{
        margin: 1.25rem 0;
        padding-top: 1.25rem;
    }
}
@media (max-width: 399.98px) {
    .rs-footer .rs-footer-content p{
        margin-top: 1rem;
        font-size: 15px;
    }
    .rs-footer .rs-footer-content ul li a{
        font-size: 15px;
    }
    .rs-footer .rs-footer-content img{
        height: 24px;
    }
    .rs-footer .rs-copyright{
        margin: 1rem 0;
        padding-top: 1rem;
        font-size: 15px;
    }
}
@media (max-width: 349.98px) {
    .rs-footer .rs-footer-content p{
        margin-top: .75rem;
        font-size: 14px;
    }
    .rs-footer .rs-copyright{
        margin: .75rem 0;
        padding-top: .75rem;
        font-size: 14px;
    }
}
@media (max-width: 319.98px) {
}

/*  ==================================================
    2. Privacy Policy
================================================== */
.privacy-container {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "PT Sans", sans-serif !important;
    font-size: 19px;
    font-weight: 400;
    color: #2b2b2b;
    overflow-x: hidden;
    line-height: normal;
    transition: all 200ms linear;
}
.privacy-container section {
    position: relative;
    width: 100%;
    display: block;
    padding: 80px 0;
    overflow: hidden;
}
.privacy-container .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.privacy-container ul {
    list-style: none;
    padding-left: 1rem;
}
.privacy-container li {
    padding-left: 1em;
    text-indent: -0.7em;
}
.privacy-container li::before {
    content: "• ";
    color: #a09fa0;
    font-size: 22px;
}
.privacy-container strong {
    font-weight: bold !important;
}
.privacy-container .content {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100vh;
}
.privacy-container .content h1 {
    font-size: 75px;
}
.privacy-container .text-center {
    text-align: center !important;
}
.privacy-container .word-break {
    word-break: break-all;
}
.privacy-container .hero-privacy {
    text-align: center;
    height: auto !important;
    background: #f3f3f3;
    padding: 80px 0;
}
.privacy-container .hero-privacy .hero-content h1 {
    font-size: 70px;
    font-weight: 800;
    margin-bottom: 1rem;
    margin-top: 0;
}
.privacy-container .hero-privacy .hero-content span {
    display: inline-block;
    color: #fff;
    background-color: #222;
    font-weight: 800;
    font-size: 25px;
    padding: 10px 20px;
    border-radius: 50px;
    margin-bottom: 1rem;
}
.privacy-container .hero-privacy .hero-content p {
    font-size: 19px;
    color: #8a8a8a;
}
.privacy-container .privacy-policy .privacy-policy-content .mb-30 {
    margin-bottom: 30px;
}
.privacy-container .privacy-policy .privacy-policy-content h2 {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 1rem;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 1rem;
    color: #2b2b2b;
}
.privacy-container .privacy-policy .privacy-policy-content h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 0.5rem;
}
.privacy-container .privacy-policy .privacy-policy-content h4 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0.25rem;
}
.privacy-container .privacy-policy .privacy-policy-content p {
    font-size: 19px;
    color: #888;
    margin-bottom: 12px;
    word-break: break-word;
}
.privacy-container .privacy-policy .privacy-policy-content span {
    display: block;
    font-size: 19px;
    color: #888;
}
.privacy-container .privacy-policy .privacy-policy-content ul li {
    font-size: 19px;
    color: #888;
    padding-bottom: 12px;
}
.privacy-container .privacy-policy .privacy-policy-content a {
    color: #028fcc;
}
@media (min-width: 576px) {
    .privacy-container .container {
        max-width: 540px;
   }
}
@media (min-width: 768px) {
    .privacy-container .container {
        max-width: 720px;
   }
}
@media (min-width: 992px) {
    .privacy-container .container {
        max-width: 960px;
   }
}
@media (min-width: 1200px) {
    .privacy-container .container {
        max-width: 1560px;
   }
}
@media (max-width: 991.98px) {
    .privacy-container section {
        padding: 60px 0;
   }
    .privacy-container .hero-privacy {
        padding: 60px 0;
   }
    .privacy-container .hero-privacy .hero-content h1 {
        font-size: 60px;
        margin-top: 0;
   }
}
@media (max-width: 767.98px) {
    .privacy-container section {
        padding: 50px 0;
   }
    .privacy-container .hero-privacy {
        padding: 50px 0;
   }
    .privacy-container .hero-privacy .hero-content h1 {
        font-size: 55px;
        margin-bottom: 0.9rem;
   }
    .privacy-container .hero-privacy .hero-content span {
        margin-bottom: 0.9rem;
   }
    .privacy-container .hero-privacy .hero-content p {
        font-size: 17px;
   }
}
@media (max-width: 575.98px) {
    .privacy-container section {
        padding: 30px 0;
   }
    .privacy-container .hero-privacy {
        padding: 30px 0;
   }
    .privacy-container .hero-privacy .hero-content h1 {
        font-size: 32px;
        margin-bottom: 0.7rem;
   }
    .privacy-container .hero-privacy .hero-content span {
        margin-bottom: 0.7rem;
        font-size: 20px;
   }
    .privacy-container .hero-privacy .hero-content p {
        font-size: 16px;
   }
    .privacy-container br {
        display: none;
   }
    .privacy-container .privacy-policy .privacy-policy-content {
        font-size: 34px;
        font-weight: 800;
   }
    .privacy-container .privacy-policy .privacy-policy-content ul {
        padding-left: 1rem;
   }
    .privacy-container .privacy-policy .privacy-policy-content ul li {
        font-size: 17px;
   }
    .privacy-container .privacy-policy .privacy-policy-content p {
        font-size: 17px;
   }
    .privacy-container .privacy-policy .privacy-policy-content h2 {
        font-size: 22px;
   }
    .privacy-container .privacy-policy .privacy-policy-content .mb-30 {
        margin-bottom: 20px;
   }
}
@media (max-width: 319.98px) {
    .privacy-container section {
        padding: 25px 0;
   }
    .privacy-container .hero-privacy .hero-content h1 {
        font-size: 30px;
        margin-bottom: 0.5rem;
   }
    .privacy-container .hero-privacy .hero-content span {
        margin-bottom: 0.5rem;
   }
    .privacy-container .privacy-policy .privacy-policy-content h2 {
        font-size: 21px;
   }
}
